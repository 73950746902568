<template>
  <div style="font-family: PingFangSC-Regular;">
    <van-popup v-model="resultShow" position="bottom">
      <van-picker title="处理结果" show-toolbar :columns="resultOptions" value-key="label" @confirm="resultConfirm"
                  @cancel="resultShow = false"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom">
      <van-picker title="问题类别" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm"
                  @cancel="typeShow = false"/>
    </van-popup>
    <van-popup v-model="wayShow" position="bottom">
      <van-picker title="处理方式" show-toolbar :columns="wayList" value-key="label" @confirm="wayConfirm"
                  @cancel="wayShow = false"/>
    </van-popup>
    <van-popup v-model="managerShow" position="bottom">
      <van-picker title="推送到" show-toolbar :columns="managerList" value-key="label" @confirm="managerConfirm"
                  @cancel="managerShow = false"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <span class="custom-title" style="font-weight: 600; color: #333; font-size: 16px;">问题详情</span>
        </template>
      </van-cell>
      <van-cell title="问题类别" :value="typeValue" is-link/>
      <van-cell title="处理结果" :value="resultValue" @click="resultShow = !resultShow" is-link/>
<!--      <van-cell title="处理方式" v-if="dataForm.status == 20" :value="wayValue" @click="wayShow = !wayShow" is-link required />-->
<!--      <van-cell title="推送到其他人员" :value="managerValue" v-if="dataForm.status == 10" @click="managerShow = !managerShow" is-link/>-->
      <van-cell title="上报说明" required v-if="dataForm.status == 20" :border="false" />
      <van-cell title="处理描述" required v-if="dataForm.status == 0 || dataForm.status == 30" :border="false" />
      <van-field clearable clear-trigger="always" class="left" v-model="dataForm.content" :border="false" placeholder="请输入" input-align="right" rows="3" type="textarea"
                v-if="dataForm.status == 0 || dataForm.status == 30 || dataForm.status == 20" style="padding-top: 0"/>
      <van-row class="uploadBox" v-if="dataForm.status == 0 || dataForm.status == 30">
        <van-col :span="24">
          <!-- <van-uploader v-model="fileList" @delete="uploaddel" :before-read="beforeUpload" :after-read="uploadRead"/> -->
          <div class="upload">
            <upload-file
              :file-list="fileList"
              :del-btn-show="edit"
              :upload-btn-show="edit"
              @filesUpload="filesUpload"
              @delFile="delFile"
              :upload-path="uploadPath"
              :file-type="'imageOrVideo'"/>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns" style="margin-top: 12px;">
      <van-col :span="24">
        <van-button type="info" size="large" @click="submit" class="btn btn1">确定处理</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import uploadFile from '@/components/upload/uploadFile'

import {mapMutations, mapState} from 'vuex'
import {beforeFileUpload} from "@/utils/utils";

export default {
  data() {
    return {
      orgId: '',
      resultShow: false,
      typeShow: false,
      wayShow: false,
      managerShow: false,
      value: '',
      detailData: {},
      resultValue: '请选择',
      typeValue: '请选择',
      wayValue: '请选择',
      managerValue: '请选择',
      resultOptions: [
        {label: "无法处理", value: "0"},
        // {label: "推送", value: "10"},
        {label: "流转给社工", value: "20"},
        {label: "处理完成", value: "30"}
      ],
      typeList: [],
      wayList: [{label: "上报", value: "1"}, {label: "自行处理", value: "3"}],
      managerList: [],
      dataForm: {
        status: '',
        type: '',
        manager: '',
        id: '',
        fileMessages: [],
        deleteFiles: [],
        content: ''
      },
      fileList: [],
      uploadPath: 'files/problem/temporary',
      edit: true,
      problemIds: ''
    }
  },
  computed: {...mapState(['problemData', 'problemVisitIds'])},
  components: {
    topBar,
    uploadFile
  },
  methods: {
    ...mapMutations,
    filesUpload (files) {
      this.dataForm.fileMessages = files
    },
    delFile (id) {
      this.dataForm.deleteFiles.push(id)
    },
    getDetail() {
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/problem/info/zzzg'),
        method: 'post',
        params: this.$httpCustApp.adornParams({
          id: this.problemData.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.typeValue = this.$route.query.typeStr
          this.dataForm.type = this.$route.query.type
          this.dataForm.id = data.entityVO.id
          this.resultValue = data.entityVO.solveStatusStr
          this.dataForm.status = data.entityVO.solveStatus
          this.dataForm.community = data.entityVO.community
          this.dataForm.subarea = data.entityVO.subarea
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    submit() {
      if (this.dataForm.type == '') {
        return this.$toast.fail('失败:请先选择问题类别');
      }
      if (this.dataForm.status == '') {
        return this.$toast.fail('失败:请先选择处理结果');
      }
      // if (this.dataForm.status == 20 && !this.dataForm.way) {
      //   return this.$toast.fail('失败:请选择处理方式');
      // }
      // if (this.dataForm.manager == '' && this.dataForm.status == 10) {
      //   return this.$toast.fail('失败:请先选择推送人员');
      // }
      if (this.dataForm.content == '' && this.dataForm.status == 20 && this.dataForm.way == 1) {
        return this.$toast.fail('失败:请输入上报说明');
      }
      if (this.dataForm.content == '' &&( this.dataForm.status == 0 || this.dataForm.status == 30)) {
        return this.$toast.fail('失败:请输入处理描述');
      }
      if (this.dataForm.status == 20) {
        this.submitFlow()
      } else {
        this.submitSuccess()
      }
    },
    submitFlow () {
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/commonApp/problemInfo/new/flow/noLogin'),
        method: 'post',
        data: this.$httpCustApp.adornData({
          problemId: this.dataForm.id,
          type: 2,
          explained: this.dataForm.content,
          timeLimit: 0
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          localStorage.setItem('isRefresh', 1)
          this.$toast.success({message: '操作成功'})
          setTimeout(() => {
            this.$router.go(-1)
            if (this.problemIds) {
              this.$store.commit('setProblemIds',this.dataForm.id)
            }
          }, 1500)
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    submitSuccess () {
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/problem/new/solve/zzzg'),
        method: 'post',
        data: this.$httpCustApp.adornData({
          loginOrgId: this.$orgId,
          community: this.orgId,
          subarea: this.dataForm.subarea,
          solveDes: this.dataForm.content,
          newFileMessages: this.dataForm.fileMessages,
          id: this.dataForm.id,
          solveStatus: this.dataForm.status,
          problemType: this.dataForm.type,
          solveUserId: sessionStorage.getItem('orgUserId')
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          localStorage.setItem('isRefresh', 1)
          this.$toast.success({message: '处理成功'})
          setTimeout(() => {
            this.$router.go(-1)
            if (this.problemIds) {
              this.$store.commit('setProblemIds',this.dataForm.id)
            }
          }, 1500)
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getType() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'problemType'
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.typeList = data.dicts
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getManager() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'managerType'
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.managerList = data.dicts
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      });
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/problem/temporary');
        this.$httpCustApp({
          url: this.$httpCustApp.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    resultConfirm(val) {
      this.resultValue = val.label
      this.dataForm.status = val.value
      this.resultShow = false
    },
    typeConfirm(val) {
      this.typeValue = val.label
      this.dataForm.type = val.value
      this.typeShow = false
    },
    wayConfirm(val) {
      this.wayValue = val.label
      this.dataForm.way = val.value
      this.wayShow = false
    },
    managerConfirm(val) {
      this.managerValue = val.label
      this.dataForm.manager = val.value
      this.managerShow = false
    }
  },
  created() {
    this.getType()
    this.getManager()
    this.getDetail()
    if (this.$route.query.ids) {
      this.problemIds = this.$route.query.ids
      this.orgId = this.$route.query.orgId
    }
  },
}
</script>

<style scoped>
.left >>> .van-field__control {
  text-align: left !important;
}
.btn {
  height: 88px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #4581F8;
  color: #4581F8;
}

.btn1 {
  background-color: #4581F8;
  color: #fff;
}
</style>
